<as-split direction="horizontal">
  <as-split-area [size]="70">
    <div class="tab-panel-left">
      <mat-form-field class="fill" appearance="outline">
        <mat-label>Quality Profile</mat-label>
        <mat-select
          required
          [value]="sessionManager.qualityProfileName"
          (valueChange)="changeQuality($event)"
        >
          <mat-option
            *ngFor="let item of sessionManager.qualityProfiles"
            [value]="item.name"
          >
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <fieldset
        class="tab-page-table"
        [appDisable]="qualityProfile.name !== CUSTOM_QUALITY_PROFILE_NAME"
      >
        <legend>Advanced (Custom Profile)</legend>

        <trj-category
          [categories]="categories"
          [profile]="performanceService.customizableQualityLevel"
          (profilePropertyChange)="setCustomizableQualityLevel()"
        ></trj-category>
      </fieldset>
    </div>
  </as-split-area>
  <as-split-area
    [size]="30"
    [minSize]="15"
    class="tab-preview"
    (resized)="changeRenderSize($event)"
  >
    <div class="sticky-panel">
      <trj-settings-performance-preview
        [qualityLevel]="qualityProfile.level"
        [autoRotate]="autoRotate"
        [wireframe]="wireframe"
        #preview
      ></trj-settings-performance-preview>

      <div class="tab-page-table">
        <div class="row">
          <span class="value">
            <mat-checkbox
              color="primary"
              [checked]="autoRotate"
              (change)="autoRotate = $event.checked"
            ></mat-checkbox>
          </span>
          <span class="label"> auto rotate</span>
        </div>
        <div class="row">
          <span class="value">
            <mat-checkbox
              color="primary"
              [checked]="wireframe"
              (change)="wireframe = $event.checked"
            ></mat-checkbox>
          </span>
          <span class="label"> wireframe</span>
        </div>
      </div>
    </div>
  </as-split-area>
</as-split>

<div class="table-column">
  <div class="tab-settings"></div>
</div>
