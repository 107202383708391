<div class="tab-page">
  <div class="tab-page-table-grid">
    <button
      class="full-row"
      mat-raised-button
      color="primary"
      (click)="openCameraCreator()"
    >
      <span>Add New Animation</span>
    </button>

    <mat-form-field
      appearance="outline"
      class="full-row animations"
      *ngVar="animationService.animations$ | async as animations"
    >
      <mat-label>Animation</mat-label>
      <mat-select
        (selectionChange)="animationChange($event.value)"
        [formControl]="selectedAnimation"
      >
        <mat-option *ngFor="let animation of animations" [value]="animation">{{
          animation.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button
      class="full-row"
      mat-raised-button
      color="warn"
      (click)="removeCurrentAnimation()"
      [disabled]="(animationService.selectedAnimation$ | async) === null"
    >
      <span>Remove Current Animation</span>
    </button>

    <trj-player></trj-player>

    <trj-editor></trj-editor>
  </div>
</div>
