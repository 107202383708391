<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <p [innerHTML]="data.text"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">
    {{ data.buttonNegative ?? 'No' }}
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    [color]="data.buttonPositiveColor ?? 'primary'"
    [mat-dialog-close]="data"
  >
    {{ data.buttonPositive ?? 'Yes' }}
  </button>
</mat-dialog-actions>
