<div class="tab-page-grid" [class.xs]="isSmall">
  <span class="label">Back Color:</span>
  <span class="value" *ngVar="DEFAULT_BACK_COLOR as defaultColor">
    <span
      *ngVar="(colorService.backColor.color$ | async) ?? defaultColor as color"
      class="value custom-color-picker"
      (colorPickerChange)="changeBackColor($event)"
      [cpAlphaChannel]="'disabled'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Selection Color:</span>
  <span
    class="value"
    [appDisable]="!workspaceService.trajectory"
    *ngVar="DEFAULT_COLOR_SELECTION as defaultColor"
  >
    <span
      *ngVar="
        (colorService.selectionColor.color$ | async) ?? defaultColor as color
      "
      class="value custom-color-picker"
      (colorPickerChange)="changeDefaultSelectionColor($event)"
      [cpAlphaChannel]="'disabled'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Histone Octamer Color:</span>
  <span
    class="value"
    [appDisable]="!workspaceService.trajectory"
    *ngVar="DEFAULT_COLOR_HISTONE_OCTAMER as defaultColor"
  >
    <span
      *ngVar="
        (colorService.histoneOctamerColors.color$ | async) ??
        defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultHistoneOctamerColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Nucleosome DNA Color:</span>
  <span
    class="value"
    [appDisable]="!workspaceService.trajectory"
    *ngVar="DEFAULT_COLOR_NUCLEOSOME_DNA as defaultColor"
  >
    <span
      *ngVar="(colorService.dnaColors.color$ | async) ?? defaultColor as color"
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultNucleosomeDNAColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Linker DNA Color:</span>
  <span
    class="value"
    [appDisable]="!workspaceService.trajectory"
    *ngVar="DEFAULT_COLOR_LINKER_DNA as defaultColor"
  >
    <span
      *ngVar="
        (colorService.linkerDNAColors.color$ | async) ?? defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultDNAColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Cohesin Head Color:</span>
  <span
    class="value"
    [appDisable]="
      !workspaceService.trajectory || !cohesinService.hasCohesinMode
    "
    *ngVar="DEFAULT_COLOR_COHESIN_HEAD as defaultColor"
  >
    <span
      *ngVar="
        (colorService.cohesinHeadColors.color$ | async) ?? defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultCohesinHeadColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Cohesin Ring Color:</span>
  <span
    class="value"
    [appDisable]="
      !workspaceService.trajectory || !cohesinService.hasCohesinMode
    "
    *ngVar="DEFAULT_COLOR_COHESIN_RING as defaultColor"
  >
    <span
      *ngVar="
        (colorService.cohesinRingAndHingeColors.color$ | async) ??
        defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultCohesinRingNHingeColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Cohesin "Linker" Color:</span>
  <span
    class="value"
    [appDisable]="
      !workspaceService.trajectory || !cohesinService.hasCohesinMode
    "
    *ngVar="DEFAULT_COLOR_COHESIN_LINKER as defaultColor"
  >
    <span
      *ngVar="
        (colorService.cohesinLinkerColors.color$ | async) ??
        defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultCohesinLinkerColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <span class="label">Default Base Pair Color:</span>
  <span
    class="value"
    [appDisable]="
      !workspaceService.trajectory ||
      sessionManagerService.qualityLevel.linkerDNABuilder !==
        ELinkerDNABuilderType.DOUBLE_HELIX
    "
    *ngVar="DEFAULT_COLOR_BASE_PAIR as defaultColor"
  >
    <span
      *ngVar="
        (colorService.basePairColors.color$ | async) ?? defaultColor as color
      "
      class="custom-color-picker"
      (colorPickerChange)="changeDefaultBasePairColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[defaultColor]"
      [colorPicker]="color"
    >
      <span [style.background]="color"></span>
    </span>
  </span>
</div>
