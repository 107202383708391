<button mat-button [matMenuTriggerFor]="menuDebug">Debug</button>
<mat-menu #menuDebug="matMenu">
  <button mat-menu-item (click)="saveLayout()">
    <mat-icon>folder_open</mat-icon>
    <span>Save Layout</span>
  </button>
  <button mat-menu-item disabled>
    <mat-icon>get_app</mat-icon>
    <span>Save Camera Position</span>
  </button>
</mat-menu>
