<div
  class="tab-page-grid"
  [class.xs]="isSmall"
  [appDisable]="!pbcService.pbcMode"
>
  <span class="label">Enable:</span>
  <span class="value">
    <mat-checkbox
      [checked]="pbcService.microscopeSlideEnableState"
      (change)="pbcService.microscopeSlideEnableState = $event.checked"
      color="primary"
    ></mat-checkbox>
  </span>

  <span class="label">Box:</span>
  <span class="value">
    <mat-checkbox
      [checked]="pbcService.microscopeSlideBoxVisibleState"
      (change)="pbcService.microscopeSlideBoxVisibleState = $event.checked"
      [appDisable]="!pbcService.microscopeSlideEnableState"
      color="primary"
    ></mat-checkbox>
  </span>

  <span class="label subcategory">Color:</span>
  <span class="value">
    <span
      *ngVar="pbcService.microscopeSlideBoxColor$ | async as color"
      class="value custom-color-picker"
      (colorPickerChange)="changeMicroscopeSlideBoxColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[DEFAULT_COLOR_MICROSCOPE_SLIDE_BOX]"
      [colorPicker]="color"
      [appDisable]="!pbcService.microscopeSlideEnableState"
    >
      <span [style.background]="color"></span>
    </span>
  </span>

  <div class="single-row">
    <fieldset
      class="slider"
      [appDisable]="!pbcService.microscopeSlideEnableState"
    >
      <legend>Size</legend>

      <trj-slider
        [value]="pbcService.microscopeSlideCutSize"
        [maximum]="pbcService.getContainerSize()"
        (valueChange)="changeMicroscopeSlideCubeCutSize($event)"
        [minWidthInputField]="75"
        [isSmall]="isSmall"
      ></trj-slider>
    </fieldset>
  </div>

  <div class="single-row">
    <fieldset [appDisable]="!pbcService.microscopeSlideEnableState">
      <legend>Index</legend>

      <trj-slider
        [value]="pbcService.microscopeSlideIndex"
        [minimum]="0"
        [maximum]="microscopeSlideMaxIndex"
        (valueChange)="changeMicroscopeSlideCubeIndex($event)"
        [minWidthInputField]="75"
        [isSmall]="isSmall"
      ></trj-slider>

      <trj-player-controls
        [appDisable]="!pbcService.microscopeSlideEnableState"
        (first)="setFirstIndex()"
        (previous)="setPreviousIndex()"
        (next)="setNextIndex()"
        (last)="setLastIndex()"
        [isPauseStartVisible]="false"
        [isPause]="true"
        [disableControlsOnPause]="false"
        [isFirstEnabled]="pbcService.microscopeSlideIndex > 0"
        [isPreviousEnabled]="pbcService.microscopeSlideIndex > 0"
        [isNextEnabled]="
          pbcService.microscopeSlideIndex < microscopeSlideMaxIndex
        "
        [isLastEnabled]="
          pbcService.microscopeSlideIndex < microscopeSlideMaxIndex
        "
      >
      </trj-player-controls>
    </fieldset>
  </div>
</div>
