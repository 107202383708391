<div class="tab-page" [appDisable]="maxConfig <= 0">
  <div class="slider">
    <trj-slider
      [value]="currentConfig"
      [maximum]="maxConfig"
      (valueChange)="changeCurrentConfig($event)"
      [minWidthInputField]="75"
      [isSmall]="isSmall"
    ></trj-slider>
  </div>

  <trj-player-controls
    (first)="setFirstConfiguration()"
    (previous)="previousConfiguration()"
    (pauseStart)="playAndPause()"
    (next)="nextConfiguration()"
    (last)="lastConfiguration()"
    [isPause]="timerID < 0"
    [isFirstEnabled]="loop || (!loop && this.currentConfig > 0)"
    [isPreviousEnabled]="loop || (!loop && this.currentConfig > 0)"
    [isNextEnabled]="loop || (!loop && this.currentConfig !== this.maxConfig)"
    [isLastEnabled]="loop || (!loop && this.currentConfig !== this.maxConfig)"
  >
  </trj-player-controls>

  <div class="tab-page-grid sub-options" [class.xs]="isSmall">
    <div class="label">Monte Carlo Step:</div>
    <div class="value">
      <strong>{{ mcStep }}</strong>
    </div>

    <div class="label">Loop:</div>
    <div class="value">
      <mat-checkbox
        [checked]="loop"
        (change)="loop = $event.checked"
        color="primary"
      ></mat-checkbox>
    </div>

    <div class="label">Interval:</div>
    <div class="value">
      <mat-form-field
        class="mat-form-interval padding-remover"
        appearance="outline"
      >
        <input
          matInput
          [min]="MINIMAL_INTERVAL"
          [max]="MAXIMAL_INTERVAL"
          step="1"
          [(ngModel)]="interval"
          (change)="changeInterval(interval)"
          type="number"
      /></mat-form-field>
    </div>
  </div>

  <!--
    <mat-grid-tile>Movement:</mat-grid-tile>
    <mat-grid-tile>
      <mat-checkbox class="space-left" color="primary"></mat-checkbox>
    </mat-grid-tile>
    -->
</div>
