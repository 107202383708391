<mat-accordion>
  <mat-expansion-panel *ngFor="let category of categories">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ category.name }}
      </mat-panel-title>
      <mat-panel-description *ngIf="CATEGORY_DESCRIPTION[category.name]">
        {{ CATEGORY_DESCRIPTION[category.name] }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let item of category.items" class="item">
      <trj-category-item
        [item]="item"
        [(selectedValue)]="profile[item.property]"
        (selectedValueChange)="profilePropertyChange.emit()"
      >
      </trj-category-item>
    </div>
  </mat-expansion-panel>
</mat-accordion>
