<div class="explorer">
  <mat-form-field class="field-filter" appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="5" #input />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" aria-label="All histones">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="selected">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
          color="primary"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
          color="primary"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="displayIndex">
      <th mat-header-cell *matHeaderCellDef id="index">Index</th>
      <td mat-cell *matCellDef="let bead">{{ bead.displayIndex }}</td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef id="id">ID</th>
      <td mat-cell *matCellDef="let bead">{{ bead.id }}</td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef id="displayName">Name / ID</th>
      <td mat-cell *matCellDef="let bead">{{ bead.displayName }}</td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef id="color">Color</th>
      <td
        mat-cell
        *matCellDef="let bead"
        (click)="$event.stopImmediatePropagation()"
      >
        <span
          class="value custom-color-picker"
          (colorPickerChange)="changeSelectionColor($event, bead)"
          [cpPresetColors]="defaultColor"
          [(colorPicker)]="selectionColor"
          (click)="$event.stopImmediatePropagation(); $event.preventDefault()"
          (colorPickerOpen)="setSelectionColor(bead)"
        >
          <span [style.background]="bead.color"></span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
    ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" *ngIf="input.value.length > 0">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[50, 100, 200]"
    showFirstLastButtons
    class="paginator"
  ></mat-paginator>
</div>
