<h1 mat-dialog-title>Animation Name</h1>
<mat-dialog-content>
  <p>Specify a unique name to be able to reuse the animation in the future.</p>
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.name" (keyup.enter)="submit(data.name)" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!data.name"
    [mat-dialog-close]="data"
  >
    Okay
  </button>
</mat-dialog-actions>
