<div
  class="holder"
  [style.backgroundColor]="backColor"
  [class.tool-distance-measurement-holder]="
    distanceMeasurementService.isDistanceMeasurement$ | async
  "
  (mousemove)="setToolDistanceMeasurementMovement($event)"
  (mousedown)="setToolDistanceMeasurementFirstPoint($event)"
  (mouseup)="setToolDistanceMeasurementSecondPoint($event)"
>
  <div class="stats" id="stats"></div>
  <canvas
    #tmpDistanceMeasurement2D
    class="full-screen"
    id="tool-distance-measurement"
    [style.display]="(isMouseDownForSecondPoint$ | async) ? 'block' : 'none'"
  ></canvas>
  <canvas
    #coordinateCross
    id="coordinate-cross"
    [style.display]="viewService.showCoordinateCross$$.value ? 'block' : 'none'"
  ></canvas>
  <trj-webxr-view-button id="web-xr-view-button"></trj-webxr-view-button>
  <canvas
    #rendererCanvas
    class="full-screen"
    [class.tool-distance-measurement]="
      distanceMeasurementService.isDistanceMeasurement$ | async
    "
    (dblclick)="onDoubleClickOnViewer($event)"
  ></canvas>
  <div class="information-loader" *ngIf="!workspaceService.trajectory">
    <span>
      Please Load a Trajectory File or Try an
      <a
        class="link-example"
        [routerLink]="['/']"
        [queryParams]="{
          provider: 'gitlab',
          repo: GITLAB_REPO_ID,
          ref: GITLAB_REPO_REF,
          file: GITLAB_REPO_FILE
        }"
        >Example</a
      >.
    </span>
  </div>
</div>
