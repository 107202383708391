<div class="pop-up">
  <span class="icon material-icons" [style.color]="iconColor">{{ icon }}</span>
  <span class="content">
    <span>{{ title }}</span>
    <span class="description" [innerHTML]="description"></span>
  </span>

  <button
    mat-icon-button
    (click)="closeClicked.emit(this)"
    class="button-close"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
