<div class="tab-page">
  <fieldset class="tab-margin">
    <legend>Colors</legend>

    <trj-view-settings-colors [isSmall]="isSmall"></trj-view-settings-colors>
  </fieldset>

  <fieldset class="tab-margin">
    <legend>Visualization</legend>

    <trj-view-settings-visualization
      [isSmall]="isSmall"
    ></trj-view-settings-visualization>
  </fieldset>
</div>
