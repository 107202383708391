<div class="tab-page" [appDisable]="!workspaceService.trajectory">
  <fieldset class="tab-page-grid" [class.xs]="isSmall">
    <legend>Colors</legend>

    <span class="label">Start Color:</span>
    <span class="value">
      <span
        class="value custom-color-picker"
        [cpPresetColors]="[]"
        [(colorPicker)]="startColor"
      >
        <span [style.background]="startColor"></span>
      </span>
    </span>

    <span class="label">End Color:</span>
    <span class="value">
      <span
        class="value custom-color-picker"
        [cpPresetColors]="[]"
        [(colorPicker)]="endColor"
      >
        <span [style.background]="endColor"></span>
      </span>
    </span>
  </fieldset>

  <fieldset>
    <legend>Selection</legend>

    <div class="tab-page-table-grid">
      <span class="label select-box-padding">Start Bead (Index):</span>
      <span class="value">
        <mat-form-field
          class="grid-item-field"
          appearance="outline"
          [appDisable]="maxBeadIndex < 0"
        >
          <input
            matInput
            min="0"
            [max]="maxBeadIndex"
            step="1"
            [(ngModel)]="startIndex"
            (change)="changeBeadStartIndex(startIndex)"
            type="number"
          />
          <mat-hint>{{ selectedStartBead }}</mat-hint>
        </mat-form-field>
      </span>
      <div class="two-cols selection-buttons">
        <button
          mat-stroked-button
          color="primary"
          class="simple-button"
          (click)="setMinimum()"
        >
          Set minimum
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="simple-button"
          [disabled]="true"
        >
          TODO: Click on a bead
        </button>
      </div>

      <span class="label select-box-padding">End Bead (Index):</span>
      <span class="value">
        <mat-form-field
          class="grid-item-field"
          appearance="outline"
          [appDisable]="maxBeadIndex < 0"
        >
          <input
            matInput
            min="0"
            [max]="maxBeadIndex"
            step="1"
            [(ngModel)]="endIndex"
            (change)="changeBeadEndIndex(endIndex)"
            type="number"
          />
          <mat-hint>{{ selectedEndBead }}</mat-hint>
        </mat-form-field>
      </span>
      <div class="two-cols selection-buttons">
        <button
          mat-stroked-button
          color="primary"
          class="simple-button"
          (click)="setMaximum()"
        >
          Set maximum
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="simple-button"
          [disabled]="true"
        >
          TODO: Click on a bead
        </button>
      </div>

      <mat-form-field
        class="two-cols label-affected-beads"
        appearance="outline"
      >
        <mat-label>Affected Beads</mat-label>
        <mat-select [formControl]="beadTypeSelection" multiple>
          <mat-select-trigger>
            {{
              beadTypeSelection.value && beadTypeSelection.value.length > 0
                ? beadTypeSelection.value[0].text
                : ''
            }}
            <span
              *ngIf="(beadTypeSelection.value?.length ?? 0) > 1"
              class="example-additional-selection"
            >
              (+{{ (beadTypeSelection.value?.length ?? 0) - 1 }}
              {{
                (beadTypeSelection.value?.length ?? 0) === 2
                  ? 'other'
                  : 'others'
              }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let beadType of beadTypes" [value]="beadType">{{
            beadType.text
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-row center">
      Affected beads amount: {{ affectedBeads }}
    </div>
  </fieldset>

  <div class="full-row submit-button-holder">
    <button
      mat-raised-button
      color="primary"
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="affectedBeads === 0"
    >
      Change Colors
    </button>
  </div>
</div>
