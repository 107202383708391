<div
  class="full-size loading"
  *ngIf="assetsService.loadingState$ | async; else dockableArea"
>
  <trj-loading-dialog
    *ngVar="
      (assetsService.loadingStateInfo$ | async) ??
      { file: '-', index: 0, total: 0 } as info
    "
    title="Loading Assets"
    [textLeft]="info.file"
    [textRight]="info.index + ' / ' + info.total"
  ></trj-loading-dialog>
</div>
<ng-template #dockableArea>
  <trj-dockable-area
    [fallbackLayout]="defaultLayout"
    [layout]="cachedLayout"
    class="full-size"
    (resized)="workspaceSizeChanged($event)"
    [@openClose]="'to-fade-in'"
  ></trj-dockable-area>
</ng-template>
