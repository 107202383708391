<h1 mat-dialog-title>Archive Explorer</h1>
<mat-dialog-content class="dialog-content">
  <trj-archive-directory-explorer
    (selectedNode)="setSelectedNode($event)"
    [rootNode]="rootNode"
    *ngIf="rootNode"
  ></trj-archive-directory-explorer>
  <trj-single-file-loader *ngIf="!rootNode"></trj-single-file-loader>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="rootNode">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="selectFile()"
    [disabled]="!selectedNode"
  >
    Select
  </button>
</mat-dialog-actions>
