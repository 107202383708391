<div class="tab-page-grid" [class.xs]="isSmall">
  <mat-form-field class="full-row fill" appearance="outline">
    <mat-label>Theme</mat-label>
    <mat-select
      required
      [(ngModel)]="settingsComponentService.settings.appearance.theme"
    >
      <mat-option *ngFor="let item of THEMES" [value]="item.value">
        {{ item.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span class="value">
    <mat-checkbox
      color="primary"
      [(ngModel)]="
        settingsComponentService.settings.appearance.animationEnabled
      "
    ></mat-checkbox>
  </span>
  <span class="label description-value">
    <div><strong>Animation</strong></div>
    <div>
      Activates small animations while using the application. <br />
      <strong>Note:</strong> The page must be reloaded for the change to take
      effect (after saving).
    </div>
  </span>
</div>
