<h1
  mat-dialog-title
  [class.no-margin-title]="!!paper.paper.authors || !!paper.paper.doi"
>
  {{ paper.paper.title }}
</h1>

<div class="paper-info" *ngIf="!!paper.paper.authors || !!paper.paper.doi">
  <ng-container *ngIf="!!paper.paper.authors">
    <div>Authors:</div>
    <div class="authors">
      <span *ngFor="let author of getAuthors()">{{ author }}</span>
      <span *ngIf="isAuthorListShorted()">...</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!!paper.paper.doi">
    <div>DOI:</div>
    <div>
      <a
        href="https://doi.org/{{ paper.paper.doi }}"
        class="jet-brains-link"
        rel="noopener"
        target="_blank"
        >{{ paper.paper.doi }}</a
      >
    </div>
  </ng-container>
</div>

<mat-dialog-content
  class="dialog-content"
  [class.has-paper-info]="!!paper.paper.authors || !!paper.paper.doi"
>
  <p>
    There are several trajectories available for choosing. Please select a file.
  </p>
  <p>
    Switching within the application is possible under "File -> Trajectories" in
    the top menu.
  </p>

  <trj-file-list
    [files]="paper.files"
    (selectedFile)="setSelectedFile($event)"
    (submitFile)="submitFile($event)"
  ></trj-file-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    (click)="selectFile()"
    [disabled]="!selectedFile"
  >
    Select
  </button>
</mat-dialog-actions>
