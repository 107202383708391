<div class="tab-page" [appDisable]="!workspaceService.trajectory">
  <div class="tab-page-grid" [class.xs]="isSmall">
    <div class="single-row">
      <button
        mat-raised-button
        (click)="clearSelections()"
        color="primary"
        class="button"
        [disabled]="
          ((workspaceService.selectionTexts$ | async)?.length ?? 0) <= 0
        "
      >
        Clear Selections
      </button>
    </div>

    <div>Selections:</div>
    <div
      *ngIf="
        ((workspaceService.selectionTexts$ | async)?.length ?? 0) > 0;
        else noSelections
      "
    >
      <ul class="list">
        <li *ngFor="let selection of workspaceService.selectionTexts$ | async">
          {{ selection }}
        </li>
      </ul>
    </div>
    <ng-template #noSelections>
      <div>-</div>
    </ng-template>
  </div>
</div>
