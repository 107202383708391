<h1 mat-dialog-title>About</h1>
<mat-dialog-content class="dialog-content">
  <p>
    The <strong>Chromatin 3D Visualizer</strong> is a web application for
    viewing chromatin chains in a 3D space in the browser. A chromatin chain is
    a biological structure formed by DNA and histones. The application allows
    viewing chromatin chains (with Cohesin/CTCF), navigating/moving around the
    chain, viewing the chain as a PBC container, coloring single or all histones
    and much more!
  </p>
  <div class="info-table">
    <div>
      <div class="row-small-space-to-right-side">Publisher:</div>
      <div>
        <a
          href="https://bioinformatics.hochschule-stralsund.de/"
          target="_blank"
          rel="noopener noreferrer"
          class="jet-brains-link"
          >Bioinformatics, University of Applied Sciences Stralsund</a
        >
      </div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Source Code:</div>
      <div>
        <a
          href="{{ GITLAB_DOMAIN }}"
          class="jet-brains-link"
          rel="noopener"
          target="_blank"
          >GitLab</a
        >
      </div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Version:</div>
      <div>{{ PACKAGE_VERSION }} ({{ BUILD_DATE }})</div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Commit:</div>
      <div>
        <a
          href="{{ GITLAB_DOMAIN_COMMITS }}/{{ COMMIT }}"
          class="jet-brains-link"
          rel="noopener"
          target="_blank"
          >{{ COMMIT }}</a
        >
      </div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Frontend Framework:</div>
      <div>Angular</div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Licence:</div>
      <div>{{ LICENSE }}</div>
    </div>
    <div>
      <div class="row-small-space-to-right-side">Authors:</div>
      <div>
        <div class="info-table">
          <div *ngFor="let author of AUTHORS">
            <div>
              {{ author.name }}
              <span
                class="website"
                *ngIf="author.website && author.websiteName"
              >
                <a
                  href="{{ author.website }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="lui-link"
                >
                  {{ author.websiteName }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary">
    Okay, cool!
  </button>
</mat-dialog-actions>
