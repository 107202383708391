<span>
  <button
    mat-icon-button
    color="primary"
    (click)="first.emit()"
    [disabled]="!isFirstEnabled || (disableControlsOnPause && !isPause)"
  >
    <mat-icon>skip_previous</mat-icon>
  </button>
</span>
<span>
  <button
    mat-icon-button
    color="primary"
    (click)="previous.emit()"
    [disabled]="!isPreviousEnabled || (disableControlsOnPause && !isPause)"
  >
    <mat-icon class="rotated-180-degree">play_arrow</mat-icon>
  </button>
</span>
<span *ngIf="isPauseStartVisible">
  <button mat-icon-button color="primary" (click)="pauseStart.emit(!isPause)">
    <mat-icon *ngIf="isPause; else stop">play_circle_outline</mat-icon>
    <ng-template #stop>
      <mat-icon>stop</mat-icon>
    </ng-template>
  </button>
</span>
<span>
  <button
    mat-icon-button
    color="primary"
    (click)="next.emit()"
    [disabled]="!isNextEnabled || (disableControlsOnPause && !isPause)"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>
</span>
<span>
  <button
    mat-icon-button
    color="primary"
    (click)="last.emit()"
    [disabled]="!isLastEnabled || (disableControlsOnPause && !isPause)"
  >
    <mat-icon>skip_next</mat-icon>
  </button>
</span>
