<div class="tab-page" [appDisable]="!workspaceService.trajectory">
  <fieldset class="table">
    <legend>Controls</legend>

    <mat-form-field appearance="outline">
      <mat-label>Control Type</mat-label>
      <mat-select
        [(value)]="selectedControlType"
        (selectionChange)="changeControls($event.value)"
      >
        <mat-option
          *ngFor="let control of CONTROL_TYPES"
          value="{{ control.value }}"
          >{{ control.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fieldset>

  <fieldset class="table">
    <legend>Camera</legend>

    <mat-form-field appearance="outline">
      <mat-label>Set Camera</mat-label>
      <mat-select
        [(value)]="selectedCameraSetter"
        (selectionChange)="changeSetter($event.value)"
      >
        <mat-option
          *ngFor="let setter of CAMERA_SETTER"
          value="{{ setter.value }}"
          >{{ setter.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      (click)="triggerSetCameraToCenterOfMass()"
      color="primary"
    >
      Find Fiber
    </button>
  </fieldset>
</div>
