<div class="tab-page-grid">
  <span class="value">
    <mat-checkbox
      color="primary"
      [(ngModel)]="
        settingsComponentService.settings.export.exportInvisibleObjectsGLTF
      "
    ></mat-checkbox>
  </span>
  <span class="label description-value">
    <div><strong>Export Invisible Objects to glTF</strong></div>
    <div>
      Whether to include invisible objects of the visualizer in the exported
      glTF file. If unchecked, these objects are excluded.
    </div>
  </span>
</div>
