<h1 mat-dialog-title>Password Required</h1>
<mat-dialog-content class="dialog-content">
  <p>
    The selected file is encrypted. Please enter the password of the file or
    select another file.
  </p>
  <mat-form-field appearance="outline" class="field">
    <mat-label>Password</mat-label>
    <input
      [type]="showPassword ? 'text' : 'password'"
      matInput
      [formControl]="passwordFormControl"
      (keyup.enter)="checkPassword(passwordInput.value)"
      #passwordInput
    />
    <mat-error *ngIf="passwordFormControl.hasError('incorrect')">
      <strong>The password is incorrect.</strong>
    </mat-error>
    <mat-icon
      matSuffix
      *ngIf="!showPassword"
      (click)="showPassword = !showPassword"
      class="icon"
      >visibility</mat-icon
    >
    <mat-icon
      matSuffix
      *ngIf="showPassword"
      (click)="showPassword = !showPassword"
      class="icon"
      >visibility_off</mat-icon
    >
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="passwordInput.value.length === 0 || reading"
    (click)="checkPassword(passwordInput.value)"
  >
    Enter
  </button>
</mat-dialog-actions>
