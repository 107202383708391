<div class="footer">
  <div class="main-cell">
    <a
      href="https://bioinformatics.hochschule-stralsund.de/"
      target="_blank"
      rel="noopener noreferrer"
      class="jet-brains-link"
      >Bioinformatics, University of Applied Sciences Stralsund</a
    >
  </div>
  <div>Created by:</div>
  <div>
    <a
      href="https://www.lui-studio.net/"
      target="_blank"
      rel="noopener noreferrer"
      class="lui-link"
    >
      Leif-Kristof Schultz
    </a>
  </div>
  <div>and contributors</div>
</div>
