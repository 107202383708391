<ng-container *ngIf="getOptionDecorator(); else numberRange">
  <trj-category-item-option
    [decorator]="getOptionDecorator()!"
    [conditions]="conditions"
    [(selectedValue)]="selectedValue"
    [displayName]="getDisplayNameDecorator()"
    (selectedValueChange)="selectedValueChange.emit($event)"
  ></trj-category-item-option>
</ng-container>

<ng-template #numberRange>
  <ng-container *ngIf="getNumberRangeDecorator()">
    <trj-category-item-number
      [decorator]="getNumberRangeDecorator()!"
      [conditions]="conditions"
      [(selectedValue)]="selectedValue"
      [displayName]="getDisplayNameDecorator()"
      [propName]="item.property"
      (selectedValueChange)="selectedValueChange.emit($event)"
    >
    </trj-category-item-number>
  </ng-container>
</ng-template>
