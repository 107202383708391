{{ displayName ?? propName }}

<trj-slider
  [appDisable]="!performanceService.areConditionsMet(conditions)"
  [minimum]="decorator.value.min"
  [maximum]="decorator.value.max"
  [(value)]="selectedValue"
  (valueChange)="selectedValueChange.emit($event)"
>
</trj-slider>
