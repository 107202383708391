<div class="tab-page" [class.xs]="isSmall" [appDisable]="!hasCohesin">
  <div class="tab-page-grid">
    <span class="label">Cohesin Distance:</span>
    <span class="value">
      <strong>{{ cohDistance }}</strong>
    </span>

    <span class="label">Cohesin Head Radius:</span>
    <span class="value">
      <strong> {{ headRadius }}</strong>
    </span>

    <span class="label">Ring Radius:</span>
    <span class="value">
      <strong> {{ ringRadius }}</strong>
    </span>

    <span class="label">Ring Thickness:</span>
    <span class="value">
      <strong> {{ ringThickness }}</strong>
    </span>
  </div>
</div>
