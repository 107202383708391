<button mat-button [matMenuTriggerFor]="menuFile">File</button>
<mat-menu #menuFile="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="trajectories"
    [style.display]="
      (trajectoryListerService.trajectories?.files?.length ?? 0) > 0
        ? 'block'
        : 'none'
    "
  >
    <mat-icon>folder_special</mat-icon>
    <span>Trajectories</span>
  </button>
  <button mat-menu-item (click)="openFileSelector()">
    <mat-icon>file_open</mat-icon>
    <span>Load File from Disk</span>
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="exportList"
    [disabled]="!workspaceService.trajectory"
  >
    <mat-icon>get_app</mat-icon>
    Export
  </button>
  <button
    mat-menu-item
    (click)="unloadTrajectory()"
    [disabled]="!workspaceService.trajectory"
  >
    <mat-icon>clear_all</mat-icon>
    <span>Unload Trajectory</span>
  </button>
</mat-menu>

<mat-menu #exportList="matMenu">
  <button
    mat-menu-item
    (click)="exportView()"
    matTooltip="Exports the current trajectory, with the current view (camera and color settings)"
    matTooltipPosition="right"
  >
    <mat-icon>folder_zip</mat-icon>
    <span>Shareable</span>
  </button>
  <button mat-menu-item (click)="exportModel()">
    <mat-icon>insert_drive_file</mat-icon>
    <span>glTF 3D model</span>
  </button>
</mat-menu>

<mat-menu #trajectories="matMenu">
  <ng-container
    *ngFor="
      let trajectory of (trajectoryListerService.trajectories$ | async)?.files
    "
  >
    <button
      *ngIf="isLoadedFile(trajectory.path); else selectableFile"
      mat-menu-item
      [appDisable]="true"
    >
      <mat-icon>star</mat-icon>
      <span>{{ trajectory.name }}</span>
    </button>
    <ng-template #selectableFile>
      <button
        mat-menu-item
        [routerLink]="['/']"
        [queryParams]="{ file: trajectory.path }"
        queryParamsHandling="merge"
      >
        <span>{{ trajectory.name }}</span>
      </button>
    </ng-template>
  </ng-container>
</mat-menu>
