<div class="holder" [class.xs]="isSmall">
  <span class="mat-slider-holder">
    <mat-slider
      class="slider"
      color="primary"
      [class.xs]="isSmall"
      [min]="minimum"
      [max]="maximum"
      [step]="step"
    >
      <input
        matSliderThumb
        [value]="value"
        (valueChange)="changeValue($event)"
      />
    </mat-slider>
  </span>

  <span class="sub-stats" [class.xs]="isSmall">
    <span class="numeric-box">
      <mat-form-field
        class="mat-form-current-index padding-remover"
        appearance="outline"
      >
        <input
          matInput
          [min]="minimum"
          [max]="maximum"
          [step]="step"
          [(ngModel)]="value"
          (change)="valueChange.emit(value)"
          type="number"
        />
      </mat-form-field>
    </span>
    <span class="maximum-value" [style.min-width]="minWidthInputField + 'px'">
      / {{ maximum }}</span
    >
  </span>
</div>
