<div
  class="tab-page"
  [class.xs]="isSmall"
  [appDisable]="!workspaceService.trajectory"
>
  <div class="tab-page-grid">
    <div class="label">File:</div>
    <div class="value file-name">
      <strong>{{ currentFileName }}</strong>
      <div
        class="parents"
        *ngIf="((workspaceService.triggeredFiles$ | async)?.length ?? 0) > 0"
      >
        (Parent archives:
        <span *ngFor="let file of (workspaceService.triggeredFiles$ | async)!"
          ><strong>{{ file }}</strong></span
        >)
      </div>
    </div>

    <div class="label">Temperature:</div>
    <div class="value">
      <strong>{{ getTemperature() }}</strong>
    </div>
  </div>
</div>
