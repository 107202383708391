<button mat-button [matMenuTriggerFor]="menuHelp">Help</button>
<mat-menu #menuHelp="matMenu">
  <a
    href="{{ INTRODUCTION_WIKI_URL }}"
    class="intro-link"
    rel="noopener"
    target="_blank"
  >
    <button mat-menu-item>
      <mat-icon>help_outline</mat-icon>
      <span>Introduction</span>
    </button>
  </a>
  <button mat-menu-item (click)="openAbout()">
    <mat-icon>info_outline</mat-icon>
    <span>About</span>
  </button>
</mat-menu>
