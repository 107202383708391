<fieldset
  class="fieldset-editor"
  *ngVar="animationService.selectedAnimation$ | async as animation"
  [appDisable]="animation === null"
>
  <legend>Animation Editor</legend>

  <div class="keyframe-holder">
    <div class="keyframe-boundary" [style.width]="maximalWidth + 'px'">
      <div
        *ngFor="let keyframe of keyframes"
        class="keyframe-movable"
        cdkDragBoundary=".keyframe-boundary"
        cdkDragLockAxis="x"
        cdkDrag
        (cdkDragMoved)="move($event, keyframe)"
        (cdkDragEnded)="moveEnded()"
        [matTooltip]="getCameraName(keyframe.keyframe.cameraID)"
        matTooltipPosition="below"
        [cdkDragFreeDragPosition]="keyframe.point"
        (contextmenu)="openContextMenu($event, keyframe.keyframe)"
      >
        <div class="keyframe"></div>
      </div>
      <div
        class="current-time"
        [style.left]="(animationService.time$$ | async) + 'px'"
      ></div>
    </div>
  </div>

  <button
    class="full-row"
    mat-raised-button
    color="primary"
    (click)="addKeyframe()"
  >
    <span>Add Keyframe (Camera Point)</span>
  </button>

  <button
    class="full-row"
    mat-raised-button
    color="primary"
    (click)="openRotationGenerator()"
  >
    <span>Generate Keyframes (Rotation)</span>
  </button>

  <button
    class="full-row"
    mat-raised-button
    color="warn"
    (click)="removeAllKeyframes()"
    [disabled]="keyframes.length <= 0"
  >
    <span>Remove All Keyframes</span>
  </button>
</fieldset>

<div
  class="context-menu-holder"
  [style.left]="menuPosition.x"
  [style.top]="menuPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>

<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="removeKeyframe(item)">Remove</button>
  </ng-template>
</mat-menu>
