<h2 mat-dialog-title>Settings</h2>
<mat-dialog-content>
  <div class="container">
    <mat-tab-group selectedIndex="0">
      <!--      <mat-tab label="View"><trj-settings-view></trj-settings-view></mat-tab>-->
      <mat-tab label="Behaviour"
        ><trj-settings-behaviour></trj-settings-behaviour
      ></mat-tab>
      <mat-tab label="Appearance"
        ><trj-settings-appearance></trj-settings-appearance
      ></mat-tab>
      <mat-tab label="Performance"
        ><trj-settings-performance #performance></trj-settings-performance
      ></mat-tab>
      <mat-tab label="Notification"
        ><trj-settings-notification></trj-settings-notification
      ></mat-tab>
      <mat-tab label="Export"
        ><trj-settings-export></trj-settings-export
      ></mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" autofocus (click)="saveAndClose()">
    Apply
  </button>
</mat-dialog-actions>
