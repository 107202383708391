<div class="tab-page">
  <button
    class="full-row"
    mat-raised-button
    color="primary"
    (click)="addCamera()"
  >
    <span>Add (Current) Camera</span>
  </button>

  <mat-selection-list class="list" [multiple]="false">
    <mat-list-option
      *ngFor="let camera of cameras$ | async"
      (dblclick)="setCamera(camera)"
      matTooltip="Double click to apply the position"
      matTooltipPosition="after"
    >
      <div class="option-item">
        <div mat-line>{{ camera.name }}</div>
        <button mat-icon-button (click)="renameCamera(camera)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="removeCamera(camera)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
