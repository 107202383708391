<button mat-button [matMenuTriggerFor]="menuWindow" (onMenuOpen)="menuOpen()">
  Window
</button>
<mat-menu #menuWindow="matMenu">
  <span *ngFor="let tab of tabs">
    <button
      mat-menu-item
      [name]="tab.jsonName"
      (click)="toggleTab($event, tab)"
      *ngIf="!!tab"
    >
      <mat-checkbox color="primary" [checked]="tab.isOpen"></mat-checkbox>
      {{ tab.fullNameInMenu ?? tab.displayName }}
    </button>
    <mat-divider *ngIf="!tab"></mat-divider>
  </span>
</mat-menu>
