<div class="tab-page" [appDisable]="!hasPBCMode">
  <div class="tab-page-grid" [class.xs]="isSmall">
    <span class="label">PBC Mode:</span>
    <span class="value">
      <mat-checkbox
        [checked]="pbcService.pbcMode"
        (change)="pbcService.pbcMode = $event.checked"
        color="primary"
      ></mat-checkbox>
    </span>

    <span class="label">Container:</span>
    <span class="value">
      <mat-checkbox
        [appDisable]="!pbcService.pbcMode"
        [checked]="pbcService.pbcContainerVisibleState"
        (change)="pbcService.pbcContainerVisibleState = $event.checked"
        color="primary"
      ></mat-checkbox>
    </span>

    <span class="label subcategory">Color:</span>
    <span class="value">
      <span
        *ngVar="pbcService.pbcContainerColor$ | async as color"
        class="value custom-color-picker"
        [appDisable]="
          !pbcService.pbcMode || !pbcService.pbcContainerVisibleState
        "
        (colorPickerChange)="changePBCContainerColor($event)"
        [cpAlphaChannel]="'always'"
        [cpPresetColors]="[DEFAULT_COLOR_PBC_BOX]"
        [colorPicker]="color"
      >
        <span [style.background]="color"></span>
      </span>
    </span>

    <span class="label">Container Size:</span>
    <span class="value">
      {{ containerSize }}
    </span>

    <span class="label">Cube Size LJ:</span>
    <span class="value">
      {{ cubeSizeLJ }}
    </span>

    <span class="label">Cube Size DNA Nucleosome Intersection:</span>
    <span class="value">
      {{ cubeSizeDNANucItr }}
    </span>

    <span class="label">Cube Size Estat:</span>
    <span class="value">
      {{ cubeSizeEstat }}
    </span>
  </div>

  <fieldset class="microscope-slide" [appDisable]="!pbcService.pbcMode">
    <legend>Microscope Slide</legend>
    <trj-microscope-slide [isSmall]="isSmall"></trj-microscope-slide>
  </fieldset>
</div>
