<fieldset
  *ngVar="animationService.selectedAnimation$ | async as animation"
  [appDisable]="animation === null"
>
  <legend>Player</legend>

  <trj-player-controls
    *ngVar="animationService.time$$ | async as time"
    [isFirstEnabled]="time > 0"
    [isPreviousEnabled]="time > 0"
    [isPause]="_animatorTimer < 0"
    [isNextEnabled]="time < _maxTime"
    [isLastEnabled]="time < _maxTime"
    (pauseStart)="pauseStart($event)"
    (previous)="previous()"
    (next)="next()"
    (first)="first()"
    (last)="last()"
  ></trj-player-controls>
</fieldset>
