<div class="tab-page-grid" [class.xs]="isSmall">
  <div class="label">Line Color:</div>
  <div>
    <span
      class="value custom-color-picker"
      (colorPickerChange)="changeLineColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[DEFAULT_COLOR_DISTANCE_LINE]"
      [(colorPicker)]="lineColor"
    >
      <span [style.background]="lineColor"></span>
    </span>
  </div>

  <div class="label">Line Width:</div>
  <div>
    <trj-slider
      [value]="distanceMeasurementService.lineWidth"
      [minimum]="LINE_WIDTH_MINIMUM"
      [maximum]="LINE_WIDTH_MAXIMUM"
      [step]="0.25"
      (valueChange)="changeLineWidth($event)"
      [minWidthInputField]="75"
      [isSmall]="isSmall"
    ></trj-slider>
  </div>

  <div class="label">Text Color:</div>
  <div>
    <span
      class="value custom-color-picker"
      (colorPickerChange)="changeTextColor($event)"
      [cpAlphaChannel]="'always'"
      [cpPresetColors]="[DEFAULT_COLOR_DISTANCE_TEXT]"
      [(colorPicker)]="textColor"
    >
      <span [style.background]="textColor"></span>
    </span>
  </div>

  <div class="label">Text Size:</div>
  <div>
    <trj-slider
      [value]="distanceMeasurementService.textSize"
      [minimum]="TEXT_SIZE_MINIMUM"
      [maximum]="TEXT_SIZE_MAXIMUM"
      [step]="1"
      (valueChange)="changeTextSize($event)"
      [minWidthInputField]="75"
      [isSmall]="isSmall"
    ></trj-slider>
  </div>
</div>
