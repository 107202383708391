<mat-selection-list [multiple]="false">
  <mat-list-option
    *ngFor="let file of files"
    (click)="selectedFile.emit(file)"
    (dblclick)="submitFile.emit(file)"
  >
    <mat-icon matListItemIcon *ngIf="!isZip(file.path); else zipIcon"
      >description</mat-icon
    >
    <ng-template #zipIcon>
      <mat-icon matListItemIcon>folder_zip</mat-icon></ng-template
    >
    <div matListItemTitle>{{ file.name }}</div>
  </mat-list-option>
</mat-selection-list>
