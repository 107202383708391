<div class="tab-page-grid" [class.xs]="isSmall">
  <span class="value">
    <mat-checkbox
      color="primary"
      [checked]="settingsComponentService.settings.behaviour.autoPBC"
      (change)="
        settingsComponentService.settings.behaviour.autoPBC = $event.checked
      "
    ></mat-checkbox>
  </span>
  <span class="label description-value">
    <div><strong>Automatic PBC Mode</strong></div>
    <div>
      Activates the PBC mode automatically as soon as a trajectory is loaded
      which contains a PBC container.
    </div>
  </span>

  <span class="value">
    <mat-checkbox
      color="primary"
      [checked]="settingsComponentService.settings.behaviour.autoFindFiber"
      (change)="
        settingsComponentService.settings.behaviour.autoFindFiber =
          $event.checked
      "
    ></mat-checkbox>
  </span>
  <span class="label description-value">
    <div><strong>Automatic "Find Fiber"</strong></div>
    <div>
      Sets the camera on the chain automatically as soon as a trajectory has
      been loaded.
    </div>
  </span>
</div>
