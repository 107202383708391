<div class="tab-page-grid">
  <span class="value">
    <mat-checkbox
      color="primary"
      [checked]="showUpdateNotification"
      (change)="changeShowUpdateNotification($event.checked)"
    ></mat-checkbox>
  </span>
  <span class="label description-value">
    <div><strong>Update Notification</strong></div>
    <div>Show update notification if the application has been updated.</div>
  </span>
</div>
