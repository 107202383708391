<div *ngIf="xrSupported">
  <button
    mat-raised-button
    #toggleXRButton
    class="xr-button"
    color="primary"
    (click)="toggleImmersiveXR()"
    [appDisable]="!workspaceService.trajectory"
  >
    {{ sessionActive ? 'Stop XR Session' : 'Enter XR Session' }}
  </button>
</div>
