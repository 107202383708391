<h1 mat-dialog-title>Camera Name</h1>
<mat-dialog-content>
  <p>Specify a unique name to be able to reuse the camera in the future.</p>
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input
      matInput
      [(ngModel)]="data.name"
      (keyup.enter)="submit(data.name)"
      cdkFocusInitial
    />
  </mat-form-field>
  <mat-hint *ngIf="isAlreadyInUse(data.name)" class="accent-color">
    The name already exists, this will not cause an error, but may make it
    difficult to keep track.</mat-hint
  >
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!data.name"
    [mat-dialog-close]="data"
  >
    Okay
  </button>
</mat-dialog-actions>
