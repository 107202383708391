<h1 mat-dialog-title>{{ title }}</h1>
<div class="dialog-content" mat-dialog-content>
  <mat-progress-bar
    [mode]="progressType"
    [value]="progressValue"
  ></mat-progress-bar>
  <div class="progress-text">
    <span>{{ textLeft }}</span>
    <span>{{ textRight }}</span>
  </div>
</div>
