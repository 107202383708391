<h1 mat-dialog-title>Introduction</h1>
<mat-dialog-content>
  <p>
    Thank you for using the Chromatin 3D Visualizer. You can find help for using
    the application in the
    <a
      href="{{ HELP_URL }}"
      rel="noopener"
      target="_blank"
      class="jet-brains-link"
      >Wiki</a
    >.
  </p>
  <p>
    This help can also be found within the application under the
    <strong>Help</strong> menu at <strong>Introduction</strong>.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button cdkFocusInitial mat-raised-button mat-dialog-close color="primary">
    Okay
  </button>
</mat-dialog-actions>
