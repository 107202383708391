<p>
  The archive contains more than one "trj"/"xml" file. Please choose a file.
</p>
<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="explorer-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    (click)="setSelection(node)"
    [class.selected]="node.isSelected"
  >
    <!-- use a disabled button to provide padding for tree leaf -->
    <mat-icon class="icon-node-type" *ngIf="!node.entry.directory"
      >description</mat-icon
    >
    <mat-icon class="icon-node-type" *ngIf="node.entry.directory"
      >folder</mat-icon
    >
    <mat-icon class="icon-node-type" *ngIf="node.entry.encrypted"
      >lock</mat-icon
    >
    {{ node.name }}
    <span class="label-size">{{
      getFileSize(node.entry.uncompressedSize)
    }}</span>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div
      class="mat-tree-node"
      matTreeNodeToggle
      [attr.aria-label]="'Toggle ' + node.name"
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
      <mat-icon class="icon-node-type">folder</mat-icon>
      {{ node.name }}
    </div>
    <div
      [class.explorer-tree-invisible]="!treeControl.isExpanded(node)"
      role="group"
    >
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
