<button mat-button [matMenuTriggerFor]="menuView">View</button>
<mat-menu #menuView="matMenu">
  <button
    mat-menu-item
    (click)="makeScreenshot()"
    [disabled]="!workspaceService.trajectory"
  >
    <mat-icon>screenshot</mat-icon>
    <span>Make Screenshot</span>
  </button>
  <mat-divider></mat-divider>
  <button
    mat-menu-item
    (click)="resetCamera()"
    [disabled]="!workspaceService.trajectory"
  >
    <mat-icon>preview</mat-icon>
    <span>Reset Camera</span>
  </button>
  <button mat-menu-item (click)="resetLayout()">
    <mat-icon>reset_tv</mat-icon>
    <span>Reset Layout</span>
  </button>
</mat-menu>
