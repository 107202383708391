<div
  class="tab-page-grid"
  [class.xs]="isSmall"
  [appDisable]="!workspaceService.trajectory"
>
  <span class="label">Show Coordinate Cross:</span>
  <span class="value">
    <mat-checkbox
      [checked]="viewService.showCoordinateCross$$.value"
      (change)="viewService.showCoordinateCross$$.next($event.checked)"
      color="primary"
    ></mat-checkbox>
  </span>

  <span class="label">Texture:</span>
  <span class="value">
    <mat-checkbox
      [checked]="isMaterialWithTexture()"
      (change)="changeMaterial($event.checked)"
      color="primary"
    ></mat-checkbox>
  </span>

  <span class="label">Glossy Material:</span>
  <span class="value">
    <mat-checkbox
      [checked]="viewService.glossyMaterial$$.value"
      (change)="viewService.glossyMaterial$$.next($event.checked)"
      color="primary"
    ></mat-checkbox>
  </span>
</div>
