<div class="tab-page" [class.xs]="isSmall">
  <div class="tab-page-grid">
    <div class="single-row">
      <button
        mat-raised-button
        (click)="toggleToolDistanceMeasure()"
        color="primary"
        class="button"
      >
        {{
          !distanceMeasureService.isDistanceMeasurement
            ? 'Start Distance Measurement'
            : 'Cancel Distance Measurement'
        }}
      </button>
    </div>

    <div class="single-row">
      <button
        mat-raised-button
        (click)="raycastService.clearDistanceMeasurementLines$$.next(null)"
        color="primary"
        class="button"
        [disabled]="(raycastService.existsTempLines$$ | async) === false"
      >
        Clear View
      </button>
    </div>

    <div class="label">First Point:</div>
    <div>
      <ng-container
        [ngTemplateOutlet]="
          (distanceMeasureService.firstPoint$ | async) ? point : nonValue
        "
        [ngTemplateOutletContext]="{
          interPoint: (distanceMeasureService.firstPoint$ | async)?.point
        }"
      ></ng-container>
    </div>

    <div class="label">Second Point:</div>
    <div>
      <ng-container
        [ngTemplateOutlet]="
          (distanceMeasureService.secondPoint$ | async) ? point : nonValue
        "
        [ngTemplateOutletContext]="{
          interPoint: (distanceMeasureService.secondPoint$ | async)?.point
        }"
      ></ng-container>
    </div>

    <ng-template #nonValue>-</ng-template>

    <ng-template #point let-point="interPoint">
      <ul class="point-coordinate-list">
        <li>
          <strong>X: {{ getUserFriendlyFloat(point.x) }}</strong>
        </li>
        <li>
          <strong>Y: {{ getUserFriendlyFloat(point.y) }}</strong>
        </li>
        <li>
          <strong>Z: {{ getUserFriendlyFloat(point.z) }}</strong>
        </li>
      </ul>
    </ng-template>

    <div class="label">Distance:</div>
    <strong>
      <ng-container *ngIf="distance | async; let distanceVal; else: nonValue"
        >{{ getUserFriendlyFloat(distanceVal) }} {{ UNIT }}</ng-container
      >
    </strong>

    <fieldset class="single-row visual-options">
      <legend>Visualization Options</legend>

      <trj-visualization-options
        [isSmall]="isSmall"
      ></trj-visualization-options>
    </fieldset>
  </div>
</div>
