<h1 mat-dialog-title>Rotation Generator</h1>
<mat-dialog-content>
  <p>
    Specify the axis to rotate around, the rest will be generated automatically.
  </p>
  <div class="axis-table">
    <mat-form-field appearance="outline">
      <mat-label>Axis X</mat-label>
      <input matInput [(ngModel)]="rotationVec.x" [step]="1" type="number" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Axis Y</mat-label>
      <input matInput [(ngModel)]="rotationVec.y" [step]="1" type="number" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Axis Z</mat-label>
      <input matInput [(ngModel)]="rotationVec.z" [step]="1" type="number" />
    </mat-form-field>
  </div>
  <mat-form-field class="duration" appearance="outline">
    <mat-label>Duration (per Keyframe, in FPS)</mat-label>
    <input matInput [(ngModel)]="duration" [min]="1" [step]="1" type="number" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="submit()">Generate</button>
</mat-dialog-actions>
