<mat-form-field
  class="fill"
  appearance="outline"
  [appDisable]="!performanceService.areConditionsMet(conditions)"
>
  <mat-label>{{ displayName ?? decorator.name }}</mat-label>
  <mat-select
    required
    [value]="selectedValue"
    (valueChange)="selectedValueChange.emit($event)"
  >
    <mat-option *ngFor="let item of keyValues" [value]="item.value">
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
