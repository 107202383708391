<h1 mat-dialog-title>Camera</h1>
<mat-dialog-content>
  <p>Adds a camera as new keyframe.</p>
  <mat-form-field appearance="outline" class="full-row">
    <mat-label>Camera</mat-label>
    <mat-select (selectionChange)="cameraChange($event.value)">
      <mat-option *ngFor="let camera of cameras | async" [value]="camera.id">{{
        camera.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!data.cameraID"
    [mat-dialog-close]="data"
  >
    Okay
  </button>
</mat-dialog-actions>
