<div class="tab-page" [class.xs]="isSmall">
  <div class="tab-page-grid">
    <span class="label">Stats:</span>
    <span class="value">
      <mat-checkbox
        [checked]="debugService.showStats$$.value"
        (change)="debugService.showStats$$.next($event.checked)"
        color="primary"
      ></mat-checkbox>
    </span>
    <span class="label">Fiber Box:</span>
    <span class="value">
      <mat-checkbox
        [checked]="debugService.showFiberBox$$.value"
        (change)="debugService.showFiberBox$$.next($event.checked)"
        color="primary"
      ></mat-checkbox>
    </span>
    <span class="label">Center Of Mass:</span>
    <span class="value">
      <mat-checkbox
        [checked]="debugService.showCenterOfMass$$.value"
        (change)="debugService.showCenterOfMass$$.next($event.checked)"
        color="primary"
      ></mat-checkbox>
    </span>
    <span class="label">Animation Keyframes (Camera Positions):</span>
    <span class="value">
      <mat-checkbox
        [checked]="debugService.showAnimationKeyframes$$.value"
        (change)="debugService.showAnimationKeyframes$$.next($event.checked)"
        color="primary"
      ></mat-checkbox>
    </span>
  </div>
</div>
